import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {customAxios} from "../../axios";
import {Input} from "../../components/Input";

export const SubscriberView = () => {
    const { id } = useParams()
    const [subscriber, setSubscriber] = useState({})

    const getSubscriber = async () => {
        try {
            const { status, data } = await customAxios.get(`/ajax/subscriber/${id}`)
            if (status === 200) {
                setSubscriber(data)
            }
        } catch (e) {}
    }

    useEffect(() => {
        getSubscriber().then()
    }, [id])

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '30%', background: '#f7f7f7', padding: '15px',margin: '20px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                {subscriber?.bot && <Input className={'mt10'} label={'Бот'} otherProps={{ value: subscriber?.bot?.name, disabled: true }} />}
                <Input label={'Ім`я'} otherProps={{ value: `${subscriber?.first_name} ${subscriber?.last_name || ''}`, disabled: true }} />
                <Input className={'mt10'} label={'username'} otherProps={{ value: subscriber?.username, disabled: true }} />
                <Input className={'mt10'} label={'Телефон'} otherProps={{ value: subscriber?.phone, disabled: true }} />
                <Input className={'mt10'} label={'chat_id'} otherProps={{ value: subscriber?.chat_id, disabled: true }} />
                <Input className={'mt10'} label={'Статус'} otherProps={{ value: subscriber?.status ? 'Активний' : 'Неактивний', disabled: true }} />
                <Input className={'mt10'} label={'Теги'} otherProps={{ value: (subscriber?.tags || []).map(el => (el.name)).join(', '), disabled: true }} />
            </div>
            <div>

            </div>
        </div>
    )
}