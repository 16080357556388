import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactFlow, {
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Background,
    Handle,
    Position,
    MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import axios from "axios";
import {Modal} from "../../components/Modal";
import {Input} from "../../components/Input";
import {ChooseToDo} from "./modals/ChooseToDo";
import {SendMessageModal} from "./modals/SendMessageModal";
import {useParams} from "react-router-dom";
import {customAxios} from "../../axios";
import {SubscribeToFlowModal} from "./modals/SubscribeToFlow";
import {ContactRequestModal} from "./modals/ContactRequestModal";
import {SmartDelayModal} from "./modals/SmartDelay";
import {HttpRequest} from "./modals/HttpRequest";
import {SendPictureModal} from "./modals/SendPicture";
import {AddTagModal} from "./modals/AddTag";
import {AddButtonModal} from "./modals/AddButton";
import {ConditionModal} from "./modals/Condition";
import {RelatedLoader} from "../../components/Loader";

const initialNodes = [
    {
        id: 'horizontal-1',
        sourcePosition: 'right',
        type: 'input',
        data: { label: 'Input' },
        position: { x: 0, y: 80 },
    },
    {
        id: 'horizontal-2',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'A Node' },
        position: { x: 250, y: 0 },
    },
    {
        id: 'horizontal-3',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 3' },
        position: { x: 250, y: 160 },
        style: { backgroundColor: '#e8e8e8' }
    },
    {
        id: 'horizontal-4',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 4' },
        position: { x: 500, y: 0 },
    },
    {
        id: 'horizontal-5',
        sourcePosition: 'top',
        targetPosition: 'bottom',
        data: { label: 'Node 5' },
        position: { x: 500, y: 100 },
    },
    {
        id: 'horizontal-6',
        sourcePosition: 'bottom',
        targetPosition: 'top',
        data: { label: 'Node 6' },
        position: { x: 500, y: 230 },
    },
    {
        id: 'horizontal-7',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 7' },
        position: { x: 750, y: 50 },
    },
    {
        id: 'horizontal-8',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 8' },
        position: { x: 750, y: 300 },
    },
];

const initialEdges = [
    {
        id: 'horizontal-e1-2',
        source: 'horizontal-1',
        type: 'smoothstep',
        target: 'horizontal-2',
        animated: true,
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: '#707070',
        },
        label: 'marker size and color',
        style: {
            strokeWidth: 1,
            stroke: '#707070',
        },
    },
    {
        id: 'horizontal-e1-3',
        source: 'horizontal-1',
        type: 'smoothstep',
        target: 'horizontal-3',
        animated: true,
    },
    {
        id: 'horizontal-e1-4',
        source: 'horizontal-2',
        type: 'smoothstep',
        target: 'horizontal-4',
        label: 'edge label',
    },
    {
        id: 'horizontal-e3-5',
        source: 'horizontal-3',
        type: 'smoothstep',
        target: 'horizontal-5',
        animated: true,
    },
    {
        id: 'horizontal-e3-6',
        source: 'horizontal-3',
        type: 'smoothstep',
        target: 'horizontal-6',
        animated: true,
    },
    {
        id: 'horizontal-e5-7',
        source: 'horizontal-5',
        type: 'smoothstep',
        target: 'horizontal-7',
        animated: true,
    },
    {
        id: 'horizontal-e6-8',
        source: 'horizontal-6',
        type: 'smoothstep',
        target: 'horizontal-8',
        animated: true,
    },
];

const customMultiNodes = [
    {
        id: 'horizontal-1',
        sourcePosition: 'right',
        type: 'test_node',
        data: { label: 'Input' },
        position: { x: 0, y: 80 },
    },
    {
        id: 'horizontal-2',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'A Node' },
        position: { x: 350, y: 0 },
    },
    {
        id: 'horizontal-3',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 3' },
        position: { x: 350, y: 160 },
        style: { backgroundColor: '#e8e8e8' }
    },
    {
        id: 'horizontal-4',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 4' },
        position: { x: 350, y: 300 },
    },
]

const customMultiEdges = [
    {
        id: "reactflow__edge-horizontal-1-horizontal-2",
        source: "horizontal-1",
        sourceHandle: null,
        target: "horizontal-2",
        targetHandle: null
    },
    {
        id: "reactflow__edge-horizontal-1-horizontal-3",
        source: "horizontal-1",
        sourceHandle: '1',
        target: "horizontal-3",
        targetHandle: null
    },
    {
        id: "reactflow__edge-horizontal-1-horizontal-4",
        source: "horizontal-1",
        sourceHandle: '2',
        target: "horizontal-4",
        targetHandle: null
    }
]

const CustomNode = ({ data, children, heading, headingBg, hideSource, hideTarget, report, hideReportClick }) => {
    return (
        <div className={'custom-node'}>
            {report && <div className={'custom-node__report'}>
                <div>Відправлено: {data?.data?.report?.sent_count || 0}</div>
                {!hideReportClick && <div>Натиснули на кнопку: {data?.data?.report?.button_click_count || 0}</div>}
            </div>}
            {!hideSource && <Handle type="source" position={Position.Right} style={{background: '#555'}}/>}
            <div className="custom-node__heading" style={{ background: headingBg }}>
                {heading}
            </div>

            {!hideTarget && <Handle type="target" position={Position.Left} style={{background: '#555'}}/>}
            <div className={'custom-node__body'}>{children}</div>

        </div>
    );
};
const handleStyle = { background: '#555' };
const SendMessageNode = (data) => {
    return (
        <div className={'custom-node'}>
            <div className={'custom-node__report'}>
                <div>Відправлено: {data?.data?.report?.sent_count || 0}</div>
                <div>Натиснули на кнопку: {data?.data?.report?.button_click_count || 0}</div>
            </div>
            <div className="custom-node__heading">
                Відправити повідомлення
            </div>
            <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
            <Handle type="source" style={{ background: '#555', top: '10px', right: -8, left: 'inherit' }} />
            <div className={'custom-node__body'}>
                <div>
                    <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                        {JSON.parse(data.data.meta_data)?.message}
                    </div>
                    {
                        (data.data.buttons || []).map(button => (
                            <div key={button.id} style={{ position: 'relative', height: '22px', width: '100%', background: '#ebebeb', marginTop: '3px', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px',boxSizing: 'border-box' }}>
                                <span  style={{ fontSize: '8px', textAlign: 'center' }}>{button.name} - {button.type}</span><a style={{ color: 'red' }} onClick={() => data.data.deleteButton(button.id)}>&#10005;</a>
                                <Handle type="source" position={Position.Right} style={{ ...handleStyle, right: '-4px', background: 'orange' }} id={button.id + ''} />
                            </div>
                        ))
                    }
                    <div onClick={() => data.data.setActionModal('add_button')} style={{ position: 'relative', height: '22px', width: '100%', background: '#5d8c51', marginTop: '3px', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', cursor: 'pointer' }}>
                        <a style={{ fontSize: '8px', textAlign: 'center' }}>Додати кнопку</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SubscribeToFlowNode = (data) => {
    return (
        <CustomNode data={data} heading={'Підписати на воронку'} hideSource>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                Підписати на воронку: {JSON.parse(data.data.meta_data)?.flow_title}
            </div>
        </CustomNode>
    )
}

const GetContactNode = (data) => {
    return (
        <CustomNode data={data} heading={'Запит контакту'} report hideReportClick>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                {JSON.parse(data.data.meta_data)?.message}<br/>
                <div style={{ position: 'relative', height: '22px', width: '100%', background: '#ebebeb', marginTop: '3px', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 10px',boxSizing: 'border-box' }}>
                    <span  style={{ fontSize: '8px', textAlign: 'center' }}>{JSON.parse(data.data.meta_data)?.request_button}</span>

                </div>

            </div>
        </CustomNode>
    )
}

const Delay = (data) => {
    return (
        <CustomNode data={data} heading={'Розумна затримка'} headingBg={'#f7d41b'}>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                {JSON.parse(data.data.meta_data)?.delay}{JSON.parse(data.data.meta_data)?.delay_type}
            </div>
        </CustomNode>
    )
}

const HttpRequestNode = (data) => {
    return (
        <CustomNode data={data} heading={'HTTP Запит'} headingBg={'#1bb8f7'}>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                <span style={{ textTransform: 'uppercase' }}>{JSON.parse(data.data.meta_data)?.method}</span>: {JSON.parse(data.data.meta_data)?.url}
            </div>
        </CustomNode>
    )
}

const AddTag = (data) => {
    return (
        <CustomNode data={data} heading={'Додати тег'} headingBg={'#00d071'}>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                <span style={{ textTransform: 'uppercase' }}>{JSON.parse(data.data.meta_data)?.name}</span>
            </div>
        </CustomNode>
    )
}

const DestroyTag = (data) => {
    return (
        <CustomNode data={data} heading={'Видалити тег'} headingBg={'#ffa2b0'}>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                <span style={{ textTransform: 'uppercase' }}>{JSON.parse(data.data.meta_data)?.name}</span>
            </div>
        </CustomNode>
    )
}

const SendPicture = (data) => {
    return (
        <CustomNode data={data} heading={'Відправити картинку'} headingBg={'#79ff65'} report hideReportClick>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '2px' }}>
                <img style={{ maxWidth: '100%' }} src={`http://localhost:4000/uploads/${JSON.parse(data.data.meta_data)?.file}`} alt=""/>
                <br/>
                <p style={{ fontSize: '8px', padding: '3px' }}>{JSON.parse(data.data.meta_data)?.value}</p>
            </div>
        </CustomNode>
    )
}

const ContactSent = (data) => {
    return (
        <CustomNode data={data} heading={'Поділився контактом'} headingBg={'#5d8c518c'} hideTarget>
            <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                <span style={{ textTransform: 'uppercase' }}>
                    Оберіть дії
                </span>
            </div>
        </CustomNode>
    )
}

const ConditionsNode = (data) => {
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)

    const getTags = async () => {
        setLoading(true)
        const { status, data } = await customAxios.get('/ajax/tag')
        if (status === 200) {
            setTags(data.items)
        }
        setLoading(false)
    }

    useEffect(() => {
        getTags().then()
    }, [])

    const conditionsTypes = { in: 'включає', nin: 'не включає' }
    const keyTypes = { tag: 'Тег' }

    if (loading) {
        return <></>
    }

    return (
        <div className={'custom-node'}>
            <div className="custom-node__heading" style={{ background: '#9f00ff9c' }}>
                Умова
            </div>
            <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
            <Handle type="source" position={Position.Right} style={{ background: 'green', width: '10px', height: '10px' }} id={'condition-true'} />
            <Handle type="source" position={Position.Bottom} style={{ background: 'red', width: '10px', height: '10px' }} id={'condition-false'} />
            <div className={'custom-node__body'}>
                <div>
                    <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                        {
                            (JSON.parse(data.data.meta_data).conditions || []).map(el => (
                                <div key={el.key} style={{ marginBottom: '5px' }}>- {keyTypes[el.key]} {conditionsTypes[el.operator]}: {tags.find(tag => +tag.id === +el.value)?.name}</div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const TestNode = (data) => {
    return (
        <div className={'custom-node'}>
            <div className="custom-node__heading">
                Відправити повідомлення
            </div>
            <Handle type="target" position={Position.Left} style={{ background: '#555' }} />
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
            <div className={'custom-node__body'}>
                <div>
                    <div style={{ border: '1px solid #f4f4f4', fontSize: '10px', padding: '8px' }}>
                        {data.id}
                    </div>
                    <div style={{ position: 'relative', height: '22px', width: '100%', background: '#ebebeb', marginTop: '3px', borderRadius: '4px' }}>
                        <Handle type="source" position={Position.Right} style={{ ...handleStyle, right: '-12px', background: 'orange' }} id={'1'} />
                    </div>
                    <div style={{ position: 'relative', height: '22px', width: '100%', background: '#ebebeb', marginTop: '3px', borderRadius: '4px' }}>
                        <Handle type="source" position={Position.Right} style={{ ...handleStyle, right: '-12px', background: 'orange' }}  id={'2'} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const nodeTypes = {
    send_message: SendMessageNode,
    subscribe_to_funnel: SubscribeToFlowNode,
    get_contact: GetContactNode,
    delay: Delay,
    http_request: HttpRequestNode,
    send_picture: SendPicture,
    add_tag: AddTag,
    destroy_tag: DestroyTag,
    test_node: TestNode,
    condition: ConditionsNode,
    contact_sent: ContactSent
};

export const ConstructorPage = () => {
    const { id } = useParams()
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);
    const [flow, setFlow] = useState(null)
    const [chooseAction, setChooseAction] = useState(null)
    const [client, setClient] = useState({})
    const [actionModal, setActionModal] = useState('')
    const [loading, setLoading] = useState(false)
    const countRef = useRef(null);

    const getFlow = async () => {
        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`ajax/flow/${id}`)
            if (status === 200) {
                setFlow(data)
                const { data: actionsData } = await customAxios.get(`ajax/action-by-flow/${data.id}`)
                const serverNodes = actionsData.items.map(el => ({
                    id: `horizontal-${el.id}`,
                    targetPosition: el.type === 'start' ? null : 'left',
                    sourcePosition: 'right',
                    type: el.type === 'start' ? 'input' : el.type,
                    data: {
                        label: 'Стартовий крок', ...el,
                        report: (actionsData.report || []).find(report => +report.id === +el.id),
                        setActionModal: (e) => { setActionModal(e); setClient(el.id) },
                        deleteButton: (id) => deleteButton(id)
                    },
                    position: { x: Number(el.coordinates_x), y: Number(el.coordinates_y) },
                }))

                /*serverNodes.push({
                    id: `horizontal-55`,
                    type: 'start',
                    sourcePosition: 'none',
                    targetPosition: 'right',
                    data: { label: 'Input child' },
                    position: { x: 200, y: 20 },
                    parentNode: 'horizontal-2',
                })*/

                setNodes(serverNodes)
                if (actionsData.connections) {
                    setEdges(actionsData.connections.map(el => ({
                        platform_id: el.id,
                        id: `reactflow__edge-horizontal-${el.action_id}-horizontal-${el.to_action_id}`,
                        source: `horizontal-${el.action_id}`,
                        type: 'bezier',
                        target: `horizontal-${el.to_action_id}`,
                        sourceHandle: (el.button_id || el.condition) ? (el.button_id || el.condition) + '' : null,
                        animated: true,
                        markerEnd: { type: MarkerType.ArrowClosed, width: 18, height: 18, color: '#707070', },
                        style: { strokeWidth: 1, stroke: '#707070', },
                    })))
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getFlow().then()
        /*setNodes(customMultiNodes)
        setEdges(customMultiEdges)*/
    }, [])

    const updateNodePosition = async (node) => {
        setLoading(true)
        const { status } = await customAxios.put(`ajax/action/${node.id.replace('horizontal-', '')}`,
            {
                coordinates_x: node.position.x,
                coordinates_y: node.position.y
            }
        )
        setLoading(false)
    }

    const deleteConnect = async (edge) => {
        if (!window.confirm('Видалити елемент?')) {
            return
        }

        setLoading(true)
        try {
            const { status } = await customAxios.post(`ajax/action/connect/delete`, {
                id: edge.platform_id
            })
            if (status === 204) {
                setEdges(edges.filter(el => el.id !== edge.id))
            }
        } catch (e) {}
        setLoading(false)
    }

    const createConnection = async (param) => {
        countRef.current = null
        setLoading(true)

        const data = {
            flow_id: id,
            action_id: param.source.replace('horizontal-', ''),
            to_action_id: param.target.replace('horizontal-', ''),

        }

        if (param.sourceHandle) {
            if (param.sourceHandle.includes('condition')) {
                data.condition = param.sourceHandle
            } else {
                data.button_id = param.sourceHandle
            }
        }

        const { status } = await customAxios.post(`ajax/action/connect/create`, data)

        param.type = 'smoothstep'
        if (status === 201) {
            //onConnect(param)
            await getFlow()
        }
        setLoading(false)
    }

    const deleteAction = async () => {
        setLoading(true)
        try {
            const { status } = await customAxios.delete(`ajax/action/${client.id}`)
            if (status === 204) {
                await getFlow()
            }
        } catch (e) {}
        setLoading(false)
    }

    const deleteButton = async (id) => {
        if (!window.confirm('Видалити кнопку?')) return

        setLoading(true)
        try {
            const { status } = await customAxios.delete(`ajax/action/button/${id}`)
            if (status === 204) {
                await getFlow()
            }
        } catch (e) {}
        setLoading(false)
    }

    const createContactSentAction = async () => {
        const el = nodes.find(el => el.type === 'contact_sent')
        if (el) {
            alert('Ця дія вже створена')
            return
        }

        const send_data = {
            type: 'contact_sent',
            flow_id: id,
            action_id: client.id,
            meta_data: JSON.stringify({}),
            coordinates_x: +client.clientX,
            coordinates_y: +client.clientY,
            sourceHandle: client.sourceHandle
        }

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`ajax/action`, send_data)
            if (status === 200 || status === 201) {
                await getFlow()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div style={{ height: '100vh', position: 'relative' }}>
            {/*{loading && <RelatedLoader />}*/}
            <ReactFlow
                nodeTypes={nodeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={createConnection}
                fitView
                nodeOrigin={[0.5, 0]}
                attributionPosition="bottom-left"
                onEdgeDoubleClick={(e, node) => {
                    deleteConnect(node).then()
                }}
                onNodeDoubleClick={(e, node) => {
                    setClient({
                        id: node.id.replace('horizontal-',''),
                        clientX: node.position.x,
                        clientY: node.position.y,
                        edit: true
                    })
                    setChooseAction(true)
                }}
                onNodeDragStop={(e, node) => updateNodePosition(node)}
                onConnectStart={(event, node) => {
                    const nodeId = node.nodeId.replace('horizontal-','')
                    const nodeData = nodes.find(el => el.id === node.nodeId)
                    countRef.current = {
                        id: nodeId,
                        clientX: nodeData.data.coordinates_x,
                        clientY: nodeData.data.coordinates_y,
                        sourceHandle: node.handleId
                    }
                }}
                onConnectEnd={() => {
                    setTimeout(() => {
                        if (countRef.current) {
                            setClient(countRef.current)
                            setChooseAction(true)
                        }
                    }, 500)
                }}
            >
                <Background />
                <Controls />
            </ReactFlow>
            {chooseAction && <ChooseToDo
                client={client}
                onClick={(type) => {
                    if (type === 'delete') {
                        deleteAction().then()
                    } else if (type === 'contact_sent') {
                        createContactSentAction()
                    } else if(type === 'edit') {
                        const el = nodes.find(el => +el.data.id === +client.id)
                        setClient({
                            ...client,
                            data: el.data
                        })
                        setActionModal(el.data.type)
                        //setActionModal()
                    } else {
                        setActionModal(type)
                    }
                    setChooseAction(false)
                }}
                close={() => setChooseAction(false)}
            />}
            {
                actionModal === 'send_message' &&
                    <SendMessageModal
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'subscribe_to_funnel' &&
                    <SubscribeToFlowModal
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'get_contact' &&
                    <ContactRequestModal
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'delay' &&
                    <SmartDelayModal
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'http_request' &&
                    <HttpRequest
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'send_picture' &&
                    <SendPictureModal
                        close={() => setActionModal('')}
                        flow_id={id}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                (actionModal === 'add_tag' || actionModal === 'destroy_tag') &&
                    <AddTagModal
                        close={() => setActionModal('')}
                        flow_id={id} actionModal={actionModal}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                (actionModal === 'add_button') &&
                    <AddButtonModal
                        close={() => setActionModal('')}
                        flow_id={id} actionModal={actionModal}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
            {
                actionModal === 'condition' &&
                    <ConditionModal
                        close={() => setActionModal('')}
                        flow_id={id} actionModal={actionModal}
                        client={client}
                        updateFlow={getFlow}
                    />
            }
        </div>
    );
};